export const NOTES_BATCH_DELETE_REQUEST = 'NOTES_BATCH_DELETE_REQUEST'
export const NOTES_BATCH_DELETE_SUCCESS = 'NOTES_BATCH_DELETE_SUCCESS'
export const NOTES_BATCH_DELETE_FAILURE = 'NOTES_BATCH_DELETE_FAILURE'

export const NOTES_FETCH_MANY_REQUEST = 'NOTES_FETCH_MANY_REQUEST'
export const NOTES_FETCH_MANY_SUCCESS = 'NOTES_FETCH_MANY_SUCCESS'
export const NOTES_FETCH_MANY_FAILURE = 'NOTES_FETCH_MANY_FAILURE'

export const NOTES_FETCH_REQUEST = 'NOTES_FETCH_REQUEST'
export const NOTES_FETCH_SUCCESS = 'NOTES_FETCH_SUCCESS'
export const NOTES_FETCH_FAILURE = 'NOTES_FETCH_FAILURE'

export const NOTES_SAVE_REQUEST = 'NOTES_SAVE_REQUEST'
export const NOTES_SAVE_SUCCESS = 'NOTES_SAVE_SUCCESS'
export const NOTES_SAVE_FAILURE = 'NOTES_SAVE_FAILURE'

export const EMPTY_NOTES = 'EMPTY_NOTES'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
