import pagination from '../../utils/pagination'
import {
  NOTES_BATCH_DELETE_REQUEST, NOTES_BATCH_DELETE_SUCCESS, NOTES_BATCH_DELETE_FAILURE,
  NOTES_FETCH_MANY_REQUEST, NOTES_FETCH_MANY_SUCCESS, NOTES_FETCH_MANY_FAILURE,
  NOTES_FETCH_REQUEST, NOTES_FETCH_SUCCESS, NOTES_FETCH_FAILURE,
  NOTES_SAVE_REQUEST, NOTES_SAVE_SUCCESS, NOTES_SAVE_FAILURE,
  EMPTY_NOTES,
  CLEAR_ERRORS
} from './types'

export default {
  [NOTES_BATCH_DELETE_REQUEST] (state) {
    state.deleting = true
  },
  [NOTES_BATCH_DELETE_SUCCESS] (state) {
    state.deleting = false
  },
  [NOTES_BATCH_DELETE_FAILURE] (state) {
    state.deleting = false
  },
  [NOTES_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [NOTES_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [NOTES_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [NOTES_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [NOTES_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [NOTES_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [NOTES_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [NOTES_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [NOTES_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_NOTES] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  }
}
